import * as React from "react";
import PageMeta from "../components/PageMeta";
import Header from "../components/Header";
import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const HeaderAndMeta = () => {
  const { t } = useTranslation();
  const langContext = React.useContext(I18nextContext);
  return (
    <>
      <PageMeta
        title={t("dcSpark * Crypto products for the next generation of finance")}
        description={t(
          "Take your first step into the ever-growing world of Blockchain. By focusing on interoperability and composability, we provide a seamless experience for end users."
        )}
        locale={t("en_US")}
        language={langContext.language}
      />
      <Header />
    </>
  );
};

export default HeaderAndMeta;
