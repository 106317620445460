import * as React from "react";
import { Helmet } from "react-helmet";

type PageMetaProps = {
  title: string;
  description: string;
  locale: string;
  language: string;
};

const PageMeta = ({ title, description, locale, language }: PageMetaProps) => {
  return (
    <Helmet htmlAttributes={{ lang: language }}>
      <html lang={language} />
      <meta charSet="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{title}</title>
      <meta name="robots" content="max-snippet:40, max-image-preview:large" />
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:locale" content={locale} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:url" content="/" />
      <meta property="og:site_name" content="dcSpark" />
      <meta property="og:image" content="https://www.dcspark.io/images/dcspark_banner.png" />
      <meta
        property="og:image:secure_url"
        content="https://www.dcspark.io/images/dcspark_banner.png"
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="627" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content="https://www.dcspark.io/images/dcspark_banner.png" />

      <link rel="apple-touch-icon" sizes="180x180" href="/images/favicon/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon-16x16.png" />
      <link rel="manifest" href="/images/favicon/site.webmanifest" />
      <link rel="mask-icon" href="/images/favicon/safari-pinned-tab.svg" color="#010a12" />
      <link rel="shortcut icon" href="/images/favicon/favicon.ico" />
      <meta name="msapplication-TileColor" content="#010a12" />
      <meta name="msapplication-config" content="/images/favicon/browserconfig.xml" />
      <meta name="theme-color" content="#ffffff" />

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;700&display=swap"
        rel="stylesheet"
      />
      <script
        type="text/javascript"
        src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
      ></script>
    </Helmet>
  );
};

export default PageMeta;
