import React, { useState } from "react";
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next";
import Accordion from "./Accordion";
import { LANGUAGES, Locales } from "../../locales/languages";

const Header = () => {
  const { language: currentLanguage, languages, originalPath } = useI18next();
  const [mobileMenu, setMobileMenu] = useState(false);
  const [rotate, setRotate] = useState("transform duration-300 ease");
  const [active, setActive] = useState(false);
  function toggleArrow() {
    setActive(active === false ? true : false);
    // @ts-ignore
    setRotate(
      active
        ? "transform duration-300 ease"
        : "transform duration-300 ease rotate-180",
    );
  }

  return (
    <>
      <div className="font-base overflow-x-hidden">
        <header
          className={`header flex items-center h-70 lg:h-auto py-16 fixed top-0 left-0 right-0 text-black transition-all z-50 ${
            mobileMenu ? "active-white" : "active"
          }`}
        >
          <div className="container">
            {/* Mobile menu */}
            <nav
              className={`mobile-main-nav z-0 lg:hidden ${
                mobileMenu ? "active" : ""
              }`}
            >
              <div className="container">
                <ul className="-mx-15 flex flex-col items-center lg:flex-row">
                  <li className="block w-full">
                    <Link
                      to="/#products"
                      onClick={() => setMobileMenu(false)}
                      className="main-nav-link"
                    >
                      <Trans>Products</Trans>
                    </Link>
                  </li>
                  <li className="block w-full">
                    <Link
                      to="https://blog.dcspark.io"
                      onClick={() => setMobileMenu(false)}
                      className="main-nav-link"
                    >
                      <Trans>Blog</Trans>
                    </Link>
                  </li>
                  <li className="block w-full">
                    <Link
                      to="/#founders"
                      onClick={() => setMobileMenu(false)}
                      className="main-nav-link"
                    >
                      <Trans>Founders</Trans>
                    </Link>
                  </li>
                  <li className="block w-full">
                    <Link
                      to="/#careers"
                      onClick={() => setMobileMenu(false)}
                      className="main-nav-link"
                    >
                      <Trans>Careers</Trans>
                    </Link>
                  </li>
                  <li className="block w-full">
                    <Link
                      to="/grants"
                      onClick={() => setMobileMenu(false)}
                      className="main-nav-link"
                    >
                      <Trans>Grants</Trans>
                    </Link>
                  </li>
                  <div className="w-full border-t border-gray-300 mt-24" />
                  <li className="block w-full">
                    <div className="my-14">
                      <Accordion />
                    </div>
                  </li>
                  <div className="w-full border-t border-gray-300 mb-24" />
                  <li className="block w-full">
                    <Link
                      to="/#contact"
                      onClick={() => setMobileMenu(false)}
                      className="main-nav-link btn-small mt-10"
                    >
                      <Trans>Contact us</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>

            <div className="flex items-center relative z-10">
              <Link
                to="/#home"
                className="block mr-auto w-110 h-22 md:w-140 md:h-28"
              >
                <img
                  className="w-full h-full object-contain"
                  width="140"
                  height="28"
                  src="/images/logo.svg"
                  alt="dcSpark logo"
                />
              </Link>
              <nav className="main-nav hidden lg:block -mx-20">
                <ul className="flex flex-col items-center lg:flex-row whitespace-nowrap">
                  <li className="block w-full px-20">
                    <Link to="/#products" className="main-nav-link">
                      <Trans>Products</Trans>
                    </Link>
                  </li>
                  <li className="block w-full px-20">
                    <Link to="https://blog.dcspark.io" className="main-nav-link">
                      <Trans>Blog</Trans>
                    </Link>
                  </li>
                  <li className="block w-full px-20">
                    <Link to="/#founders" className="main-nav-link">
                      <Trans>Founders</Trans>
                    </Link>
                  </li>
                  <li className="block w-full px-20">
                    <Link to="/#careers" className="main-nav-link">
                      <Trans>Careers</Trans>
                    </Link>
                  </li>
                  <li className="block w-full px-20">
                    <Link to="/grants" className="main-nav-link">
                      <Trans>Grants</Trans>
                    </Link>
                  </li>
                  <li
                    className="block w-full cursor-pointer"
                    onClick={toggleArrow}
                  >
                    <div className="main-nav-link dropdown relative py-0">
                      <div className="dropdown-selected bg-transparent px-20">
                        <Trans>{LANGUAGES[currentLanguage as Locales]}</Trans>
                        <svg
                          className={`${rotate} inline-block w-16 h-16 fill-current ml-5`}
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.6894 5.28443C13.2753 4.90519 12.6029 4.90519 12.1888 5.28443L8.00025 9.51089L3.81118 5.28443C3.39707 4.90519 2.7247 4.90519 2.31058 5.28443C1.89647 5.66367 1.89647 6.2805 2.31058 6.65974L7.18352 11.5761C7.40781 11.7818 7.70703 11.8731 8.00025 11.8549C8.29348 11.8731 8.5922 11.7818 8.81648 11.5761L13.6894 6.65974C14.1035 6.2805 14.1035 5.66367 13.6894 5.28443Z"
                            fill="current"
                          />
                        </svg>
                      </div>

                      <ul className="dropdown-list text-center">
                        {languages.map((lang) => (
                          <li className="dropdown-list-item-lang" key={lang}>
                            <Link to={originalPath} language={lang}>
                              <Trans>{LANGUAGES[lang as Locales]}</Trans>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                  <li className="block w-full px-20">
                    <Link to="/#contact" className="main-nav-link btn-small">
                      <Trans>Contact us</Trans>
                    </Link>
                  </li>
                </ul>
              </nav>
              <div
                className={`burger-icon lg:hidden ${
                  mobileMenu ? "active" : ""
                }`}
                onClick={() => {
                  setMobileMenu((prev) => !prev);
                }}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default Header;
